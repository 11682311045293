<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab>
        Twitch
      </v-tab>
      <v-tab>
        Twitter
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item class="pa-4">
        <StreamArea />
      </v-tab-item>
      <v-tab-item class="pa-4">
        <TwitterArea />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import StreamArea from '@/views/components/admin/social/StreamArea'
import TwitterArea from '@/views/components/admin/social/TwitterArea'

export default {
  components: {
    StreamArea,
    TwitterArea,
  },
  mounted() {
    this.$store.dispatch('social/loadStreams')
    this.$store.dispatch('social/loadTwitter')
  },
  data() {
    return {
      tab: 0,
    }
  },
}
</script>
