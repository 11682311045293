<template>
  <div>
    <Loading v-if="loading" />
    <v-row v-if="twitters != null">
      <v-col
        cols="12"
        md="3"
      >
        <v-list
          subheader
          two-line
          dense
        >
          <v-list-item
            v-for="(twitter, index) in twitters"
            :key="'twitter-' + index"
            dense
            class="bg-social-list mb-2"
          >
            <v-btn
              small
              color="error"
              class="mr-2"
              @click="onLocalDelete(index)"
            >
              <v-icon
                size="24"
                color="white"
                class="pr-1"
              >
                {{ icons.mdiTwitter }}
              </v-icon> Delete
            </v-btn>

            <v-list-item-content>
              <v-list-item-title v-text="twitter"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col
        cols="12"
        md="3"
      ></v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-col
          cols="12"
          md="12"
        >
          <v-row>
            <v-text-field
              v-model="username"
              :rules="usernameRules"
              required
              label="Twitter username"
            ></v-text-field>
            <v-btn
              class="ma-4 mt-6"
              :disabled="username.length <= 0"
              @click="onLocalAdd()"
            >
              Add user
            </v-btn>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="12"
          class="d-flex justify-start align-center"
        >
          <v-btn
            class="mr-4"
            :disabled="twitters.length > 4 || !localTwitterSet"
            @click="onLocalSubmit()"
          >
            Save changes
          </v-btn>
          <v-btn
            class="mr-4"
            @click="onLocalReset()"
          >
            Reset changes
          </v-btn>
          Max usernames: {{ twitters.length }}/4
        </v-col>
      </v-col>
    </v-row>
    <div v-else>
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { mdiTwitter, mdiDeleteForever, mdiAccountPlus } from '@mdi/js'
import { reactive, ref, set } from '@vue/composition-api'
import Loading from '@/views/components/Loading'
import axiosClient from '@/plugins/axios'

export default {
  components: {
    Loading,
  },
  data() {
    return {
      tab: 0,
    }
  },
  computed: {
    twitters() {
      return this.localTwitterSet ? this.localTwitter.value : this.$store.getters['social/GetTwitter']
    },
  },
  setup() {
    const loading = ref(false)
    const username = ref('')
    const usernameRules = [v => !!v || 'Username is required']
    let localTwitter = reactive({ value: [] })
    const localTwitterSet = ref(false)

    function onLocalSubmit() {
      loading.value = true
      axiosClient({
        url: `${this.$API}/social`,
        method: 'POST',
        data: {
          type: 'twitter',
          data: localTwitter.value,
        },
      })
        .then(result => {
          loading.value = false
          this.$store.dispatch('social/updateTwitter', localTwitter.value)
          localTwitter.value = []
          localTwitterSet.value = false
          this.$root.vtoast.show('Updated successfully!')
        })
        .catch(err => {
          loading.value = false
          console.log(err)
          this.$root.vtoast.show('An error occurred while updating!')
        })
    }

    function onLocalAdd() {
      if (username.value == '') return
      if (!localTwitterSet.value) {
        localTwitter.value = Array.from(this.$store.getters['social/GetTwitter'])
        localTwitterSet.value = true
      }

      if (localTwitter.value.find(x => x == username.value)) return
      set(localTwitter.value, localTwitter.value.length, username.value)
    }

    function onLocalDelete(index) {
      if (!localTwitterSet.value) {
        localTwitter.value = Array.from(this.$store.getters['social/GetTwitter'])
        localTwitterSet.value = true
      }
      localTwitter.value.splice(index, 1)
    }

    function onLocalReset() {
      localTwitter = []
      localTwitterSet.value = false
    }

    return {
      loading,
      username,
      usernameRules,
      localTwitter,
      localTwitterSet,
      onLocalSubmit,
      onLocalAdd,
      onLocalDelete,
      onLocalReset,
      icons: {
        mdiTwitter,
        mdiDeleteForever,
        mdiAccountPlus,
      },
    }
  },
}
</script>

<style scoped>
.v-list-item {
  height: 20px !important;
}
</style>
