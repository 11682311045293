<template>
  <div>
    <v-tabs v-model="activeTab" grow>
      <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route" exact v-if="$store.getters['user/IsAuthorized'](tab.permission)">
        {{ tab.name }}
      </v-tab>

      <v-tab-item v-for="tab of tabs" :key="tab.id" :value="tab.route" v-if="$store.getters['user/IsAuthorized'](tab.permission)">
        <router-view></router-view>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import PostArea from '@/views/components/admin/PostArea.vue'
import ReportArea from '@/views/components/admin/ReportArea.vue'
import SocialsArea from '@/views/components/admin/SocialsArea.vue'

export default {
  components: {
    PostArea,
    ReportArea,
    SocialsArea,
  },
  data() {
    return {
      activeTab: '',
      tabs: [
        { id: 1, name: 'Dashboard', route: '/admin/', permission: 'view_admin_dashboard' },
        { id: 2, name: 'Articles', route: '/admin/article', permission: 'manage_articles' },
        { id: 3, name: 'Reports', route: '/admin/report', permission: 'manage_reports' },
        { id: 4, name: 'Socials', route: '/admin/social', permission: 'manage_socials' },
      ],
    }
  },
}
</script>
